import './App.css';
import {useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";
import {Route, Routes} from 'react-router-dom'
import FormDelivery from "./components/FormDelivery/FormDelivery";
import FormDataPickup from "./components/FormDataPicker/FormDataPickup";
import FormInfoReferral from "./components/FormInfoReferral/FormInfoReferral";

function App() {
    const {onToggleButton, tg} = useTelegram();

    useEffect(() => {
        tg.ready();
    }, [])


    return (
        <div className="App">
          {/*  <Header />*/}
            <Routes>                
                <Route path={'delivery'} element={<FormDelivery />}/>                
                <Route path={'datapickup'} element={<FormDataPickup />}/>
                <Route path={'inforef'} element={<FormInfoReferral />}/>
            </Routes>
        </div>
    );
}

export default App;
