import React, {useCallback, useEffect, useState} from 'react';
import './FormDataPickup.css';
import {useTelegram} from "../../hooks/useTelegram";
import DatePicker from "react-mobile-datepicker";
import {useSearchParams} from "react-router-dom";

const FormDataPickup = () => {
    const curDate = new Date();
    const translation = {
        'RU' : {
            'Выберите время доставки': 'Выберите время доставки',
            'Выберите время самовывоза': 'Выберите время самовывоза',
            'Время работы ресторана': 'Время работы ресторана',
            'Время доставки': 'Время доставки',
            'Время самовывоза': 'Время самовывоза',
            'Выберите другое время. Время': 'Выберите другое время. Время',
            'не доступно': 'не доступно',
            'Выберите другое время.': 'Выберите другое время.',
            'Подтвердить': 'Подтвердить',
            'День': 'День',
            'Час': 'Час',
            'Мин': 'Мин',
        },
        'UZ' : {
            'Выберите время доставки': 'Yetkazib berish vaqtini tanlang',
            'Выберите время самовывоза': 'Olib ketish vaqtini tanlang',
            'Время работы ресторана': 'Restoranning ish vaqti',
            'Время доставки': 'Yetkazib berish vaqti',
            'Время самовывоза': 'Olib ketish vaqti',
            'Выберите другое время. Время': 'Boshqa vaqtni tanlang. Vaqtni',
            'не доступно': 'mavjud emas',
            'Выберите другое время.': 'Boshqa vaqtni tanlang.',
            'Подтвердить': 'Tasdiqlang',
            'День': 'Kun',
            'Час': 'Soat',
            'Мин': 'Min',
        },        
    }
    const [searchParams, setSearchParams] = useSearchParams();

    function getLang() {
        var lang = searchParams.get("lang") || "RU"
        if(!(lang in translation)) {
            lang = "RU"
        }
        return lang
    }

    function getDeliveryTime() {
        var res = 30
        var dTime = searchParams.get("deliveryTime")
        if (dTime && parseInt(dTime) >= 15) { 
            res = parseInt(dTime) - 15
        }
        return res
    }

    const timeDaily = {
        open : { hour: 0, minute: 0 },
        close : { hour: 23, minute: 59 },
        deliveryTime: getDeliveryTime(),
        cookingTime: 15,
        maxDays: 30
    }

    function fixTime(h, m) {
        h += (m / 60) >> 0;
        m = m % 60
        if (h > 23) return { hour:23, minute: 59 }
        return { hour: h, minute: m }
    }
    function getStartEnd(open, close, deliveryType) {
        if (deliveryType == 'pickup') {
            return {
                start: fixTime(open.hour, open.minute + timeDaily.cookingTime),
                end: fixTime(close.hour, close.minute)
            }
        }
        return {
            start: fixTime(open.hour, open.minute + timeDaily.deliveryTime + timeDaily.cookingTime),
            end: fixTime(close.hour, close.minute + timeDaily.deliveryTime)
        }
    }

    
    const typeTimes = {
        'delivery': getStartEnd(timeDaily.open, timeDaily.close, 'delivery'),
        'pickup': getStartEnd(timeDaily.open, timeDaily.close, 'pickup')
    }

    const specificDays = [
        {
            month: 12,
            day: 31,
            open : { hour: 11, minute: 0 },
            close : { hour: 20, minute: 0 },            
        },
        {
            month: 1,
            day: 1,
            open : { hour: 15, minute: 0 },
            close : { hour: 23, minute: 0 },            
        }
    ]

    function getDeliveryType() {
        var deliveryType = searchParams.get("type") || "delivery"
        if(!(deliveryType in typeTimes)) {
            deliveryType = "delivery"
        }
        return deliveryType
    }

    const [lang, setLang] = useState(getLang());
    const [deliveryType, setDeliveryType] = useState(getDeliveryType());

    var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
    var options2 = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

    const {tg, onClose} = useTelegram();
    const [startDate, setStartDate] = useState(minDate(curDate));
    const [validateTime, setValidateTime] = useState(true); //

    const onSendData = useCallback(() => {
        const data = {
           "time": startDate.toLocaleString("ru-RU", options2)
        }

      tg.sendData(JSON.stringify(data));
    }, [startDate])


    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])


    useEffect(() => {
        tg.expand()
        if (validateTime) {
            tg.MainButton.setParams({
                text: translation[lang]['Подтвердить']
            })
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    }, [validateTime])


    useEffect( ()=> {
        var result = new Date(startDate);
        var currentTime = curDate;
        result.setSeconds(0);
        currentTime.setSeconds(0)

        var startDeliveryTime = new Date(result);
        var endDeliveryTime = new Date(result);
        var specificDate = false;

        for (var d in specificDays) {
            if (result.getMonth()+1 == specificDays[d]['month'] && result.getDate() == specificDays[d]['day']) {
                var se = getStartEnd(specificDays[d]['open'], specificDays[d]['close'], deliveryType)
                startDeliveryTime.setHours(se.start.hour,se.start.minute,0);
                endDeliveryTime.setHours(se.end.hour,se.end.minute,59);
                setValidateTime(result >= startDeliveryTime && result <= endDeliveryTime)
                specificDate = true
            }
        }
        if(!specificDate) {
            startDeliveryTime.setHours(typeTimes[deliveryType].start.hour,typeTimes[deliveryType].start.minute,0);
            endDeliveryTime.setHours(typeTimes[deliveryType].end.hour,typeTimes[deliveryType].end.minute,59);

            setValidateTime(result >= startDeliveryTime && result <= endDeliveryTime)
        }
    },[startDate])

    const onChange = (date) => {
        setStartDate(date)
    }

    function maxDate(date) {
        var result = new Date(date);
        result.setSeconds(0);
        result.setDate(result.getDate() + timeDaily.maxDays);
        result.setHours(typeTimes[deliveryType].end.hour);
        result.setMinutes(typeTimes[deliveryType].end.minute);
        result.setSeconds(59);
        return result;
    }

    function minDate(date) {

        var result = new Date(date);
        result.setSeconds(0);
        result.setMinutes(result.getMinutes() + timeDaily.cookingTime + (deliveryType=='pickup' ? 0 : timeDaily.deliveryTime));
        -60000
        if (
            (result.getHours() < typeTimes[deliveryType].start.hour) || 
            (result.getHours() == typeTimes[deliveryType].start.hour && result.getMinutes()<typeTimes[deliveryType].start.minute) || 
            (result.getHours() > typeTimes[deliveryType].end.hour) || 
            (result.getHours() == typeTimes[deliveryType].end.hour && result.getMinutes()>typeTimes[deliveryType].end.minute)) {
            if (result.getHours() == 23) {
                result.setHours(result.getHours() + 1)
            }
            result.setHours(typeTimes[deliveryType].start.hour);
            result.setMinutes(typeTimes[deliveryType].start.minute);
        }
        return result;
    }

    function pad(num, size) {
        var s = "000000000" + num;
        return s.substring(s.length-size);
    }
    return (
        <div className={"form"}>
            <h1>{deliveryType=='pickup'?translation[lang]['Выберите время самовывоза']:translation[lang]['Выберите время доставки']}</h1>
            {/*<p>{translation[lang]['Время работы ресторана']} {timeDaily.open.hour}.{pad(timeDaily.open.minute, 2)}-{timeDaily.close.hour}.{pad(timeDaily.close.minute, 2)} </p>
            <p>/* {translation[lang]['Время доставки']} {typeTimes['delivery'].start.hour}.{pad(typeTimes['delivery'].start.minute, 2)}-{typeTimes['delivery'].end.hour}.{pad(typeTimes['delivery'].end.minute, 2)} </p>
            <p>{translation[lang]['Время самовывоза']} {typeTimes['pickup'].start.hour}.{pad(typeTimes['pickup'].start.minute, 2)}-{typeTimes['pickup'].end.hour}.{pad(typeTimes['pickup'].end.minute, 2)} </p>
*/}
            <div className={!validateTime ? 'alert alert-danger' : 'noshow'}>
                {/* {translation[lang]['Выберите другое время. Время']} {typeTimes[deliveryType].end.hour}.{pad(typeTimes[deliveryType].end.minute, 2)}- */}
                {/* {typeTimes[deliveryType].start.hour}.{pad(typeTimes[deliveryType].start.minute, 2)} {translation[lang]['не доступно']} */}
                {translation[lang]['Выберите другое время.']}
            </div>

            <DatePicker
                isOpen={true}
                isPopup={false}
                showCaption={false}
                onChange={onChange}
                theme="default"
                headerFormat='DD.MM.YYYY hh:mm'
                value={startDate != null ? startDate : minDate(curDate)}
                min={minDate(curDate)}
                max={maxDate(curDate)}
                confirmText=""
                cancelText=""
                showCaption={true}
                dateConfig={{
                    date: {
                        format: "D",
                        caption: translation[lang]['День'],
                        step: 1
                    },
                    hour: {
                        format: "hh",
                        caption: translation[lang]['Час'],
                        step: 1
                    },
                    minute: {
                        format: "mm",
                        caption: translation[lang]['Мин'],
                        step: 1
                    }
                }}
            />

        </div>

    );
};
export default FormDataPickup;
