import React from "react";

const FormInfoReferral = () => {

    return (
        <div>
            <p>
                Реферальная программа "Приведи друга"
            </p>
            <p>
                Поделитесь этим сообщением с друзьями.
                Ваши друзья, которые ещё не пользовались нашими услугами, регистрируются в нашем боте через уникальную
                ссылку (подходит только для новой регистрации).
                Как только они сделают первый заказ, вы получите 5% на свой счёт от каждого их заказа.
            </p>
            <p>
                Для вашего друга:
            </p>
            <p>
            Приветствуем!
        </p>
            <p>
    Ваш друг пригласил вас воспользоваться нашими услугами и получить бонусы! Вот что вас ждёт:

            Зарегистрируйтесь в нашем боте через уникальную ссылку (доступна только для новой регистрации).
            На ваш счёт сразу будет начислено 15 000 сум (можно использовать с первого заказа).
            При первом заказе вас также ждёт вкусный подарок — ролл "Чартак"!
            </p>
        </div>
    );



}


export default FormInfoReferral;